import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation'
import Footer from '../Footer/Footer'

import './Roofing.css';

class Roofing extends Component {
    render() {
        return (
            <div className="roofing">
                <Navigation />
                <h2>Roofing</h2>
                <p>While Illinois is a beautiful place to live, it is also home to some of the most 
                destructive weather conditions in the country. At ACPT Construction, LLC we understand 
                the importance of a strong, secure home to keep your family safe, and we want to make sure 
                our hometown is taken care of for years to come. Our residential roofing experts work 
                closely with each client to ensure that their roof can not only withstand the elements 
                but will enhance the appearance of their home. Our experienced team will present all the 
                latest information on new roofing products and technology, to help you select the most 
                reliable and affordable option to fit your needs.

                We stand behind our work with a written warranty – which is completely transferable 
                to a new owner should you sell your home. Call us, or simply fill out our contact form – for a 
                consultation and free roof estimate!

                ACPT Construction, LLC has a knowledgeable staff of roofing experts that will be happy to 
                help you choose the option that is the perfect fit for your home or business.
                </p>
                <Footer />
            </div>
        );
    }
}

export default Roofing;
