import React, { Component } from 'react';

import headerimage from '../../images/headerpic.jpg';

import './Navigation.css';

class Navigation extends Component {
    render() {
        return (
            <div className="navbar">
                <h1>
                    ACPT Construction (630)-771-1787
                </h1>
                <div className="headerimage">
                    <img src={headerimage} alt="header roofing" />
                </div>
                <ul className="navlist">
                    <li><a href='/'>Home</a></li>
                    <li><a href='/references'>Completed Projects</a></li>
                    <li><a href="/requestanestimate">Request an Estimate</a></li>
                    <li><a href="/photogallery">Photo Gallery</a></li>
                    <li><a href="/roofing">Roofing</a></li>
                    <li><a href="/carpentry">Carpentry</a></li>
                    <li><a href="/siding">Siding</a></li>
                </ul>
            </div>
        )
    }

}
export default Navigation;