import React, { Component } from 'react';

import './ContactUs.css';

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {name: '', email:'', reason:''};
    
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleReasonChange = this.handleReasonChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleNameChange(event) {
        this.setState({name: event.target.value});
      }
      handleEmailChange(event) {
        this.setState({email: event.target.value});
      }
      handleReasonChange(event) {
        this.setState({reason: event.target.value});
      }
    
      handleSubmit(event) {
        fetch('http://localhost:8000/api/email/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              name: this.state.name,
              email: this.state.email,
              message: this.state.reason,
            })
          }).then(function(data) {
            console.log('request succeeded with JSON response', data)
          }).catch(function(error) {
            console.log('request failed', error)
          })
      }

    render() {
        return (
            <form onSubmit={this.handleSubmit} className="contactUs">
            <hr/>
            <h1>Contact Us</h1>
            <label>
              Name:
              <input type="text" className="smallbox" value={this.state.name} onChange={this.handleNameChange} />
            </label>
            <br />
            <label>
              Email:
              <input type="text" value={this.state.email} onChange={this.handleEmailChange} />
            </label>
            <br />
            <label>
              Description of the reason for reaching out:
            <br />
              <textarea type="text" value={this.state.reason} onChange={this.handleReasonChange} />
            </label>
            <br />
            <input type="submit" value="Submit" />
          </form>
        );
    }
}

export default ContactUs;
