import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation'
import Footer from '../Footer/Footer'

import './Carpentry.css';

class Carpentry extends Component {
    render() {
        return (
            <div className="carpentry">
                <Navigation />
                <h2>Carpentry</h2>
                <p>
                Even though you probably hardly notice it, your exterior trim plays a vital role in 
                protecting your home. Trim work helps secure your siding and roofing to your home. 
                It also aids your gutter system. Without trim work, pests and moisture can seep 
                under your siding and roofing materials. Call Acpt Construction, LLC for brand-new 
                exterior trim installation and repair. I'll install everything from aluminum wraps 
                to PVC trim. We work with some of the best brands on the market, including AZEK 
                trim products or simple cedar boards.

                To request a free estimate for siding trim call 630-771-1787 today.
                <br />
                <br />
                DO YOU NEED NEW EXTERIOR TRIM?
                You can rely on Acpt Construction, LLC for your exterior trim. You might need new exterior 
                trim if:
                
                <ul>
                    <li>Your trim is falling off your roof or siding </li>
                    <li>Your trim work is damaged following a severe storm </li>
                    <li>You notice the wood is starting to buckle. </li>
                </ul>
                Call now to request a free estimate for exterior trim work.
                </p>
                <Footer />
            </div>
        );
    }
}

export default Carpentry;
