import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation'
import Footer from '../Footer/Footer'

import './Siding.css';

class Siding extends Component {
    render() {
        return (
            <div className="siding">
                <Navigation />
                <h2>Siding</h2>
                <p>
                    ACPT Construction features the newest and most advanced siding products available on the market today. 
                    Some of the most popular are cement board siding, LP Smart siding and vinyl siding. We can remove your old siding, 
                    install new insulation, and re-side your home to make your home more energy efficient… saving you money for years to come.
                </p>
                <h3>Siding, Soffit, Fascia, and Gutter Replacement</h3>
                <p>
                    Installing a maintenance free soffit, fascia, and gutter system is a wise decision for those 
                    who wish to eliminate the costly and hazards maintenance of these hard to reach locations of your home. 
                    As your trusted roofing and siding partner, we are determined to make covering your soffits, 
                    fascia, and gutters as easy as possible. We help provide you with all the information to help 
                    eliminate future maintenance with style and color options that best suit your home. Soffits, 
                    fascia, and gutters are parts of a house can enhance the beauty of any home, but are commonly 
                    ignored, and yet are very important. With new maintenance free soffits and fascia, painting is a 
                    thing of the past, not to mention the potential to fall off a ladder or spill paint all over your 
                    home’s exterior and landscaping. Sound familiar??? The gutters are an aid to the roofing and water 
                    management of your home. Seamless aluminum gutters are available to match your maintenance free soffits 
                    and fascia system. Our sales staff can easily guide you through the best color and style options 
                    available so you can safely enjoy your future maintenance freedom.
                </p>
                <h3>Soffits</h3>
                <p>
                    The soffit is the underlying part of your home that connects the roof and the external walls. 
                    It is joined together with the fascia on the side, where the gutters are attached. Soffits 
                    are strategically placed just where the roof eave ends and joins the house walls, the soffit 
                    can aid in the ventilation of the home’s attic. It is common for homeowners to ignore this 
                    part of the home as it is difficult to maintain. Our maintenance free soffits are the 
                    perfect solution.
                </p>
                <h3>Fascia</h3>
                <p>
                    Also known as the roofline, the fascia is the board that is joined at the point at which the 
                    roof meets the exterior walls of the house. The gutters are directly attached to the fascia 
                    unless the fascia is on a gable. Any leakage from the gutter can cause wood rot to your existing 
                    fascia.
                </p>
                <h3>Gutters</h3>
                <p>
                    The gutter is simply the water management system for a home. Can you imagine how your backyard 
                    would look if there were no gutters? The gutter system collects all the water from the roof and 
                    directs it away from your homes foundation, and therefore reducing the chance of costly foundation 
                    damage.
                </p>
                <Footer />
            </div>
        );
    }
}

export default Siding;
