import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer.js';
import ReferenceTable from '../References/ReferenceTable.js';

import './References.css';

class References extends Component {

    render() {
        return (
            <div className="references">
                <Navigation />
                <h2>Completed Projects</h2>
                <p> Here is a partial list of completed projects. Download the <a href="/static/ReferenceListCopy.xlsx" download>link</a> for the full list. </p>
                <ReferenceTable />
                    
                <Footer />
            </div>
        );
    }

}

export default References;
