import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'

import HomePage from './components/HomePage/HomePage'
import References from './components/References/References'
import RequestAnEstimate from './components/RequestAnEstimate/RequestAnEstimate'
import PhotoGallery from './components/PhotoGallery/PhotoGallery'
import Roofing from './components/Roofing/Roofing'
import Carpentry from './components/Carpentry/Carpentry'
import Siding from './components/Siding/Siding'

import './App.css';

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/references" component={References}/>
                <Route exact path="/requestanestimate" component={RequestAnEstimate}/>
                <Route exact path="/photogallery" component={PhotoGallery}/>
                <Route exact path="/roofing" component={Roofing}/>
                <Route exact path="/carpentry" component={Carpentry}/>
                <Route exact path="/siding" component={Siding}/>
            </BrowserRouter>
        );
  }
}

export default App;
