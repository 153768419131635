import React, { Component } from 'react';

import './References.css';

class ReferenceTable extends Component {

    render() {
        return (
            <div className="table">
                <table>
                    <thead>
                        <tr>
                            <th>ADDRESS</th><th>CITY</th><th>COMPLETED PROJECTS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>1103 BARKSTONE LN.</td><td>AURORA</td><td>ATTIC FAN</td></tr>
                        <tr><td>385 WILDFLOWER WAY</td><td>BOLINGBROOK</td><td>ATTIC FAN</td></tr>
                        <tr><td>5432 FLORENCE</td><td>DOWNERS GROVE</td><td>ATTIC FAN</td></tr>
                        <tr><td>2681 YORKSHIRE LN.</td><td>LISLE</td><td>ATTIC FAN</td></tr>
                        <tr><td>614 BAKEWELL LN.	        </td><td>NAPERVILLE	    </td><td>ATTIC FAN</td></tr>
                        <tr><td>13605 CAPISTA DR.	        </td><td>PLAINFIELD	    </td><td>ATTIC FAN</td></tr>
                        <tr><td>6530 MACARTHUR DR.	        </td><td>WOODRIDGE	        </td><td>ATTIC FAN</td></tr>
                        <tr><td>1126 BRUNSWICK LN.	        </td><td>AURORA 	        </td><td>CARPENTRY</td></tr>
                        <tr><td>245 WINDSOR DR.	            </td><td>BOLINGBROOK	    </td><td>CARPENTRY</td></tr>
                        <tr><td>5432 FLORENCE	            </td><td>DOWNERS GROVE	    </td><td>CARPENTRY</td></tr>
                        <tr><td>4570 BASSWOOD DR.	        </td><td>LISLE 	        </td><td>CARPENTRY</td></tr>
                        <tr><td>2307 FOX BORO	            </td><td>NAPERVILLE	    </td><td>CARPENTRY</td></tr>
                        <tr><td>13251 BROOKLAND LN.	        </td><td>PLAINFIELD	    </td><td>CARPENTRY</td></tr>
                        <tr><td>26 W. 271 EMBDEN LN.        </td><td>WHEATON	        </td><td>CARPENTRY</td></tr>
                        <tr><td>1220 MIDDLEBURY DR.	        </td><td>AURORA 	        </td><td>CHIMNEY FLASHING</td></tr>
                        <tr><td>156 WINSTON DR.	            </td><td>BOLINGBROOK	    </td><td>CHIMNEY FLASHING</td></tr>
                        <tr><td>5608 RIVERVIEW DR.	        </td><td>LISLE 	        </td><td>CHIMNEY FLASHING</td></tr>
                        <tr><td>1637 VERDIN LN.	            </td><td>NAPERVILLE	    </td><td>CHIMNEY FLASHING</td></tr>
                        <tr><td>15316 S. CORBIN	            </td><td>PLAINFIELD	    </td><td>CHIMNEY FLASHING</td></tr>
                        <tr><td>1360 BENT OAK TRAIL	        </td><td>AURORA 	        </td><td>GUTTERS</td></tr>
                        <tr><td>551 WIND MEADOW DR.	        </td><td>BOLINGBROOK	    </td><td>GUTTERS</td></tr>
                        <tr><td>4744 MIDDAUGH AVE.	        </td><td>DOWNERS GROVE	    </td><td>GUTTERS</td></tr>
                        <tr><td>5124 BARNWALL CT.	        </td><td>LISLE	            </td><td>GUTTERS</td></tr>
                        <tr><td>365 WEATHERFORD LN.	        </td><td>NAPERVILLE	    </td><td>GUTTERS</td></tr>
                        <tr><td>835 DEERPATH CT.	        </td><td>WHEATON	        </td><td>GUTTERS</td></tr>
                        <tr><td>1360 BENT OAK TRAIL	        </td><td>AURORA 	        </td><td>ROOFING</td></tr>
                        <tr><td>2530 BITMORE CIR.	        </td><td>AURORA 	        </td><td>ROOFING</td></tr>
                        <tr><td>1676 APPLE VALLEY RD.	    </td><td>BOLINGBROOK	    </td><td>ROOFING</td></tr>
                        <tr><td>18 W. 206 71ST.	            </td><td>DARIEN	        </td><td>ROOFING</td></tr>
                        <tr><td>6024 WOODWARD AVE.	        </td><td>DOWNERS GROVE	    </td><td>ROOFING</td></tr>
                        <tr><td>2346 ADLER CT.	            </td><td>LISLE	            </td><td>ROOFING</td></tr>
                        <tr><td>27 W. 161 80TH ST.	        </td><td>NAPERVILLE 	    </td><td>ROOFING</td></tr>
                        <tr><td>44 PALOMINO LN.	            </td><td>OSWEGO	        </td><td>ROOFING</td></tr>
                        <tr><td>24209 APPLE CREEK LN.	    </td><td>PLAINFIELD 	    </td><td>ROOFING</td></tr>
                        <tr><td>30 W. 030 HURLINGHAM CT.	</td><td>WARRENVILLE	    </td><td>ROOFING</td></tr>
                        <tr><td>929 NOEL CT.	            </td><td>WESTMONT	        </td><td>ROOFING</td></tr>
                        <tr><td>0 N. 107 BEVERLY	        </td><td>WHEATON	        </td><td>ROOFING</td></tr>
                        <tr><td>8832 PATRICK CT.	        </td><td>WOODRIDGE	        </td><td>ROOFING</td></tr>
                        <tr><td>2588 VICKSBURG LN.	        </td><td>AURORA 	        </td><td>SIDING </td></tr>
                        <tr><td>149 ASHCROFT DR.	        </td><td>BOLINGBROOK	    </td><td>SIDING </td></tr>
                        <tr><td>7942 GRANT ST.	            </td><td>DARIEN      	    </td><td>SIDING </td></tr>
                        <tr><td>6526 STAIR ST.	            </td><td>DOWNERS GROVE	    </td><td>SIDING </td></tr>
                        <tr><td>2355 OAKHILL DR.	        </td><td>LISLE	            </td><td>SIDING </td></tr>
                        <tr><td>25 W. 220 WOODSTOCK CT.	    </td><td>NAPERVILLE 	    </td><td>SIDING </td></tr>
                        <tr><td>401 MANCHESTER RD.	        </td><td>OSWEGO	        </td><td>SIDING </td></tr>
                        <tr><td>1401 BRIDGEHAMPTON DR.	    </td><td>PLAINFIELD 	    </td><td>SIDING </td></tr>
                        <tr><td>2151 S. HULL DR.	        </td><td>WHEATON	        </td><td>SIDING </td></tr>
                        <tr><td>6380 GREENE RD.	            </td><td>WOODRIDGE	        </td><td>SIDING </td></tr>
                        <tr><td>608 DECLARATION LN.	        </td><td>AURORA 	        </td><td>SKYLIGHTS </td></tr>
                        <tr><td>385 WILDFLOWER WAY	        </td><td>BOLINGBROOK	    </td><td>SKYLIGHTS </td></tr>
                        <tr><td>7532 EXTON	                </td><td>DARIEN	        </td><td>SKYLIGHTS </td></tr>
                        <tr><td>6024 WOODWARD AVE.	        </td><td>DOWNERS GROVE	    </td><td>SKYLIGHTS </td></tr>
                        <tr><td>5605 WESTVIEW LN.	        </td><td>LISLE	            </td><td>SKYLIGHTS </td></tr>
                        <tr><td>504 W. BAILEY RD.	        </td><td>NAPERVILLE 	    </td><td>SKYLIGHTS </td></tr>
                        <tr><td>30 W. 030 HURLINGHAM CT.	</td><td>WARRENVILLE	    </td><td>SKYLIGHTS </td></tr>
                        <tr><td>625 BLUEGRASS DR.	        </td><td>WHEATON	        </td><td>SKYLIGHTS </td></tr>
                        <tr><td>28 W. 311 PICARDY CT.	    </td><td>WINFIELD	        </td><td>SKYLIGHTS </td></tr>
                        <tr><td>2018 CLOVER LN.	            </td><td>WOODRIDGE	        </td><td>SKYLIGHTS </td></tr>
                        <tr><td>3047 QUINCY CT.	            </td><td>AURORA 	        </td><td>SOFFIT & FASCIA</td></tr>
                        <tr><td>104 S. ASHBURY AVE.	        </td><td>BOLINGBROOK	    </td><td>SOFFIT & FASCIA</td></tr>
                        <tr><td>6818 TELURIDE CT.	        </td><td>LISLE	            </td><td>SOFFIT & FASCIA</td></tr>
                        <tr><td>365 WEATHERFORD LN.	        </td><td>NAPERVILLE 	    </td><td>SOFFIT & FASCIA</td></tr>
                        <tr><td>23661 W. COTSWALD DR.	    </td><td>PLAINFIELD 	    </td><td>SOFFIT & FASCIA</td></tr>
                        <tr><td>2151 S. HULL DR.	        </td><td>WHEATON	        </td><td>SOFFIT & FASCIA</td></tr>
                        <tr><td>256 WATERFORD DR.	        </td><td>WILLOWBROOK	    </td><td>SOFFIT & FASCIA</td></tr>
                        <tr><th colSpan="3">        HOMES OF RIVER BEND ASSOCIATION		</th></tr>
                        <tr><td>ENTIRE ASSOCIATION          </td><td>LISLE	            </td><td>ROOFING PROJECT PHASE 1 & 2</td></tr>
                        <tr><td>ENTIRE ASSOCIATION	        </td><td>LISLE	            </td><td>GUTTER CLEANING</td></tr>
                        <tr><th colSpan="3"> 	    KENSINGTON PLACE TOWNHOMES	</th></tr>
                        <tr><td>6508 BARCLAY CT.	        </td><td>DOWNERS GROVE	    </td><td>ROOFING</td></tr>
                        <tr><td>6508 BARCLAY CT.	        </td><td>DOWNERS GROVE	    </td><td>SIDING </td></tr>
                        <tr><th colSpan="3">         MCKENZIE STATION TOWNHOMES		</th></tr>
                        <tr><td>ENTIRE ASSOCIATION	        </td><td>LISLE	            </td><td>GUTTER CLEANING</td></tr>
                        <tr><td>4744 LACEY AVE.	            </td><td>LISLE	            </td><td>ROOFING</td></tr>
                        <tr><th colSpan="3">         MISTWOOD CONDOMINIUMS		</th></tr>
                        <tr><td>(20) BUILDINGS IN ASSOCIATION	</td><td>DOWNERS GROVE	</td><td>GUTTERS</td></tr>
                        <tr><td>(20) BUILDINGS IN ASSOCIATION	</td><td>DOWNERS GROVE	</td><td>GUTTER GUARD</td></tr>
                        <tr><td>1020 BRAEMOOR	            </td><td>DOWNERS GROVE	ROOFING</td></tr>
                        <tr><th colSpan="3">         TOWNES OF OAKHURST ASSOCIATION		</th></tr>
                        <tr><td>ENTIRE ASSOCIATION	        </td><td>AURORA	            </td><td>ROOFING PROJECT PHASES 1,2 & 3</td></tr>
                        <tr><td>ENTIRE ASSOCIATION	        </td><td>AURORA	            </td><td>GUTTER CLEANING</td></tr>
                        <tr><th colSpan="3">      WATER TOWER COURT ASSOCIATION		</th></tr>
                        <tr><td>8445 CHICORY CT.	        </td><td>DARIEN      	    </td><td>ROOFING</td></tr>
                        <tr><th colSpan="3">         WATER TOWER RESERVE HOA		</th></tr>
                        <tr><td>2018 CLOVER LN.	            </td><td>WOODRIDGE	        </td><td>ROOFING</td></tr>
                        <tr><th colSpan="3">         WOODLANDS OF DARIEN CONDO ASSOCIATION		</th></tr>
                        <tr><td>1005 TROUTLILY LN.	        </td><td>DOWNERS GROVE	    </td><td>ROOFING</td></tr>       
                    </tbody>             
                </table>
            </div>
        );
    }

}

export default ReferenceTable;
