import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation'
import ContactUs from '../ContactUs/ContactUs';
import Footer from '../Footer/Footer';

import './RequestAnEstimate.css';

class RequestAnEstimate extends Component {
    render() {
        return (
            <div className="estimate">
                <Navigation />
                <h2>Request an Estimate</h2>
                <p>
                    To request an estimate you can reach out to me at
                    <br />
                    <br />
                    <i>
                        Todd Clark
                    <br />
                        acptconstruction@gmail.com
                    <br />
                        613 W Briarcliff, Bolingbrook, IL 60440
                    <br />
                        630-771-1787
                    <br />
                    <br />
                    </i>
                    Or fill out the form below and we will get back to you as soon as possible
                    <br />
                </p>
                    <ContactUs />
                    <Footer />
            </div>
        );
    }
}

export default RequestAnEstimate;
