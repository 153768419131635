import React, { Component } from 'react';

import Navigation from '../Navigation/Navigation'
import ContactUs from '../ContactUs/ContactUs';
import Footer from '../Footer/Footer.js';

import './HomePage.css';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numtest:0
        };
    }
    getTestimonialArray = () => {
        var arr=[];

        arr.push(
        <div>
            <h2>G. McKee</h2>
            <p>
                I've known Todd Clark for several years and have used his many services in my home and in my
                association replacing roofs and making repairs on our buildings.  He never takes shortcuts or uses
                inferior materials.  Todd is who he appears to be...a gentleman, honest and a man of his word.  
            </p>     
        </div>
        )
        arr.push(
        <div>
            <h2>Mike N.</h2>
            <p>
                When my roof started leaking last month I knew I was in trouble. I called ACPT Construction 
                and things couldn’t have been simpler. I had a new roof installed right away with no mess 
                and best of all no hassle. I would happily recommend them to anyone looking for a new roof.
            </p>     
        </div>
        )
        arr.push(
        <div>
            <h2>M. Hall</h2>
            <p>
                ACPT has my recommendation. The skylights they installed have really made a huge difference. 
                The light they let in has changed the whole dynamic of my living room. Everyone that comes 
                into my house comments on them. I’m so pleased I want put them in all my rooms. 
                
                Thanks ACPT
            </p>     
        </div>
        )
        arr.push(
            <div>
                <h2>A. Orozco</h2>
                <p>
                    I love the look of my new roof. The lighter colored shingles really give my house a different look. 
                    All the neighbors have commented on it. I recommend ACPT construction for anyone needing a roof.
                </p>     
            </div>
        )
        return arr;
    }

    render() {
        const testimonialArray = this.getTestimonialArray()
        return (
            <div className="homepage">
                <Navigation />
                <div className='services'>
                    <h2> Services </h2>
                    <h3><i>We do it right the first time</i></h3>
                    <h3><i>Serving the community since 2005</i></h3>
                    <div className="servicelist">
                        <ul>
                            <li> Roofing Tear-Offs </li>
                            <li> Reroofs </li>
                            <li> Expert Roof Repairs </li>
                            <li> Chimney Flashings </li>
                            <li> Seamless Aluminum Gutters </li>
                            <li> Gutter Repair</li>
                            <li> Aluminum Soffit and Fascia </li>
                            <li> Wood Soffit and Fascia </li>
                            <li> Soffit and Fascia Repair </li>
                            <li> Siding </li>
                            <li> Siding Repair </li>
                            <li> Windows </li>
                            <li> Doors </li>
                            <li> Insulation </li>
                            <li> Skylights </li>
                            <li> Replacement Skylights </li>
                            <li> Ventilation </li>
                            <li> Attic Fans </li>
                            <li> Roof Vents </li>
                            <li> Soffit Vents </li>
                            <li> Gable Vents </li>
                            <li> Shutters </li>
                            <li> Carpentry </li>
                            <li> Rotten Wood Replacement </li>
                        </ul>
                    </div>
                    <h2>Testimonials</h2>
                    <p>
                        We've serviced the Illinois community for 15 years now and we're excited to keep doing it.
                        We will work hard to make sure you end up with roof repair or design that you desire at the 
                        right price. No need to take it from us, here's what our clients have to say.
                    </p>
                    <div className="singleTest">
                    <i onClick={() => this.handleLeftArrowClick(testimonialArray)}><i className="fa fa-angle-double-left icon-4x"/></i>
                    {testimonialArray[this.state.numtest]}
                    <i onClick={() => this.handleLeftArrowClick(testimonialArray)}><i className="fa fa-angle-double-right icon-4x"/></i>
                    </div>
                    <div className="contactus">
                        <ContactUs />
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }

    handleRightArrowClick =  (testimonialArray) => {
        if (this.state.numtest === testimonialArray.length+1) {
            this.setState({numtest:0})
        }
        else {
            this.setState({numtest:this.state.numtest + 1})

        }
    };

    handleLeftArrowClick=  (testimonialArray) => {
        if (this.state.numtest === 0) {
            this.setState({numtest:testimonialArray.length-1})
        }
        else {
            this.setState({numtest:this.state.numtest - 1})
        }
    };
}

export default HomePage;
