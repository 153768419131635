import React, { useState, useCallback } from 'react';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer.js';

import ImageViewer from 'react-simple-image-viewer';

import './PhotoGallery.css';

function importAll(r) {
    return r.keys().map(r);
};
  

function PhotoGallery() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const images = importAll(require.context('../../images/PhotoGallery/', false, /\.(png|jpe?g|svg)$/));

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);
     
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };

    return (
        <div className="gallery">
            <Navigation />
            <h2>Photo Gallery</h2>
            <div className="photos">
                {images.map((src, index) => (
                  <div className='photo'>
                    <a>
                    <img
                      src={ src }
                      onClick={ () => openImageViewer(index) }
                      key={ index }
                      alt=""/> </a>
                  </div>
                ))}
                {isViewerOpen && (
                  <ImageViewer
                    src={ images }
                    currentIndex={ currentImage }
                    onClose={ closeImageViewer }
                  />
                )}
            </div>
            <Footer />
        </div>
     );
}

export default PhotoGallery;
