import React, { Component } from 'react';

import './Footer.css';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <hr />
                <h4>ACPT Construction (630)-771-1787</h4>
            </div>
        );
    }
}

export default Footer;
